<template>
    <div class="loginBoxBg">
        <div class="loginBox" v-if="indexType ==1">
            <form @submit.prevent="goAppLogin">
                <div>
                    <div>手机号码<span class="loginRed">*</span></div>
                    <input class="loginInput" type="number" v-model="appPhone">
                </div>
                <div class="loginS">
                    <div>验证码<span class="loginRed">*</span></div>
                        <div class="loginFelx">
                            <input class="loginInputS" type="number" v-model="appCode">
                            <div v-if="verifyShow" class="codeBox" @click="sendAppCode">获取验证码</div>
                            <div v-else class="codeBoxNo">{{verifyTip+'秒后重获'}}</div>
                        </div>
                </div>
                <button class="loginBtn" type="submit">新用户注册</button>
            </form>
       
        </div>
        <div class="loginBoxSucess" v-if="indexType ==2">
                    <img src="../assets/success.png" alt="" srcset="">
                    <span>账号注册成功！请直接</span>
                    <span>下载APP登录</span>
                <div class="loginBtn" @click="goApp">下载App</div>
            </div>
            <div class="loginBoxFail" v-if="indexType ==3">
                <img src="../assets/lost.png" alt="" srcset="">
                <span>当前账号已经存在，请直接</span>
                <span>下载APP登录</span>
            <div class="loginBtn" @click="goApp">下载App</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ghLogin",
    data() {
        return {
            appPhone:'',
            appCode:'',
            indexType:1,
            userinfoSub:{},
            shareUserId:this.$route.query.shareId,
            verifyShow:true,
            verifyTip: 60,
            getUrl:'https://api.qyyl365.com/'
        }
    },
    mounted() {
        console.log(this.$route.query.shareId)
    },
    methods: {
        timedown(num){
            let that = this;
            if(num == 0){
                that.verifyShow = true;				// 是否显示获取验证码
                return clearTimeout();
            }else{
                that.verifyShow = false;			// 是否显示获取验证码
                setTimeout(function() {  
                    that.verifyTip = num-1
                    that.timedown(num-1);  
                }, 1000);//定时每秒减一  
            }
        },
        sendAppCode(){
            var _this = this
            let myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(_this.appPhone)) {
                 _this.$message.error('请输入正确的手机号');
                return
            }
            _this.$axios.get(_this.getUrl + 'authorization/api/VerificationCode/SendVerifyCode/' + _this.appPhone)
            .then(res => {
                if (res.data.code == 200) {
                    _this.timedown(_this.verifyTip);
                    _this.$message.success('验证码发送成功');
                }
             
            })
            .catch(error => {
                console.log(error)
            });
        },
        goAppLogin(){
            var _this = this
                const params = new URLSearchParams();
                params.append('phone',_this.appPhone)
                params.append('auth_code',_this.appCode)
                params.append('grant_type','sms_auth_code')
                params.append('client_id','TotalApp')
                params.append('total_operate','scanUser')
                params.append('client_secret','480f523a-50fa-48f0-a87b-733b3dd5ecd7')
                // params.append('client_secret','41e14670-623d-4112-847e-7364ec666668')
                // params.append('username',_this.appPhone)
                // params.append('password',_this.appCode)
                // params.append('grant_type','password')
                // params.append('client_id','TotalApp')
                // params.append('client_secret','9f7fef6b-a7af-4d23-8f4c-58cb21fca5df')
                _this.$axios.post(_this.getUrl + 'authorization/connect/token',
                params.toString(), 
                { headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }},
                )
                .then(res => {
                    if (res.status == 200) {
                        let token = res.data.access_token
                        let strings = token.split("."); //截取token，获取载体
                        _this.userinfoSub = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
                        _this.$axios.get(_this.getUrl + 'authorization/api/Distributor/ShareQRCode/' + _this.shareUserId + '/' + _this.userinfoSub.sub,  { headers: {
                        'Authorization': `Bearer ${token}`
                        }},)
                        .then(res => {
                            if (res.data.code == 200) {
                                _this.indexType = 2
                            }
                        
                        })
                        .catch(error => {
                             _this.indexType = 3
                        });
                    }
                
                })
                .catch(error => {
                    console.log(error)
                    _this.$message.error('登录失败，该账号已注册');
                });
            
        },
        goApp(){
            let url = 'https://www.qyyl365.com/Home/AppServiceDown'
            window.open(url, "_blank");
        }
    }
}
</script>
<style scoped>
    .loginBox {
        width: 85%;
        padding: 30px 15px;
        font-size: 15px;
        color: #000000;
        min-height: 200px;
        background: #ffffff;
        position: absolute;
        top: 25%;
        left: 4%;
        padding-bottom: 200px;
        border-radius: 3px;
    }
    .loginRed {
        color: #FF0000;
    }
    .loginInput {
        background: #F6F9FA;
        border-radius: 3px;
        border: 1px solid #A9DAEB;
        height: 40px;
        line-height: 40px;
        width: 96%;
        font-size: 15px;
        padding-left: 10px;
        margin-top: 10px;
    }
    .loginInputS {
        background: #F6F9FA;
        border-radius: 3px;
        border: 1px solid #A9DAEB;
        height: 40px;
        line-height: 40px;
        width: 55%;
        font-size: 15px;
        padding-left: 10px;
        margin-top: 10px;
    }
    .loginS {
        margin-top: 20px;
    }
    .codeBox {
        margin-top: 10px;
        color: #3FA2C5;
        background: #F6F9FA;
        border-radius: 3px;
        border: 1px solid #A9DAEB;
        text-align: center;
        height: 40px;
        line-height: 40px;
        flex: 1;
        width: 100%;
        margin-left: 10px;
        font-size: 14px;
    }
    .codeBoxNo {
        margin-top: 10px;
        color: #666666;
        background: #F6F9FA;
        border-radius: 3px;
        border: 1px solid #666666;
        text-align: center;
        height: 40px;
        line-height: 40px;
        flex: 1;
        width: 100%;
        margin-left: 10px;
        font-size: 14px;
    }
    .loginFelx {
        display: flex;
        justify-content: space-between;
    }
    .loginBtn {
        font-size: 14px;
        color: #FFFFFF;
        background: #61B9D8;
        border-radius: 3px;
        width: 100%;
        padding: 12px 0;
        text-align: center;
        margin-top: 20px;
    }
    .loginBoxBg {
        position: relative;
        width: 100%;
        height: 800px;
        background-image: url('../assets/bg01.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .loginBoxSucess {
        width: 85%;
        padding: 30px 15px;
        font-size: 15px;
        color: #000000;
        min-height: 200px;
        background: #ffffff;
        position: absolute;
        top: 25%;
        left: 4%;
        padding-bottom: 200px;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loginBoxSucess img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }
    .loginBoxSucess span {
        font-size: 18px;
        color: #333333;
    }
    .loginBoxFail {
        width: 85%;
        padding: 30px 15px;
        font-size: 15px;
        color: #000000;
        min-height: 200px;
        background: #ffffff;
        position: absolute;
        top: 25%;
        left: 4%;
        padding-bottom: 200px;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loginBoxFail img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }
    .loginBoxFail span {
        font-size: 18px;
        color: #333333;
    }


</style>
